
import {defineComponent, computed, onMounted, watch, nextTick} from "vue";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import ArcAside from "@/core/layout/aside/Aside.vue";
import ArcHeader from "@/core/layout/header/Header.vue";
import ArcFooter from "@/core/layout/footer/Footer.vue";
import HtmlClass from "@/core/services/LayoutService";
import ArcToolbar from "@/core/layout/toolbar/Toolbar.vue";
import ArcScrollTop from "@/core/layout/extras/ScrollTop.vue";
import Loader from "@/core/components/Loader.vue";
import ArcToolButtons from "@/core/layout/extras/ToolButtons.vue";
import {Actions} from "@/core/store/enums/StoreEnums";
import {MenuComponent} from "@/core/assets/ts/components";
import {removeModalBackdrop} from "@/core/helpers/dom";
import {reinitializeComponents} from "@/core/plugins/arcui";
import persistentStorage from "@/core/store/PersistentStorage";

import {
  toolbarDisplay,
  loaderEnabled,
  contentWidthFluid,
  loaderLogo,
  asideEnabled,
  subheaderDisplay,
  themeLightLogo,
  themeDarkLogo,
} from "@/core/helpers/config";

export default defineComponent({
  name: "Layout",
  components: {
    ArcAside,
    ArcHeader,
    ArcFooter,
    ArcToolbar,
    ArcScrollTop,
    ArcToolButtons,
    Loader,
  },
  setup() {
    const vuexStore = useStore();
    const route = useRoute();
    const router = useRouter();
    const store = persistentStorage;

    // show page loading
    vuexStore.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init();

    const pageTitle = computed(() => {
      return vuexStore.getters.pageTitle;
    });

    const breadcrumbs = computed(() => {
      return vuexStore.getters.pageBreadcrumbPath;
    });

    const routeName = computed(() => {
      return route.name;
    });

    onMounted(() => {
      //check if current user is authenticated
      if ((store.authentication.user || null) == null) {
        // Go to login page
        console.log("No user - login page");
        router.push("/login");
      }

      nextTick(() => {
        reinitializeComponents();
      });

      // Simulate the delay page loading
      setTimeout(() => {
        // Remove page loader after some time
        vuexStore.dispatch(Actions.REMOVE_BODY_CLASSNAME, "page-loading");
      }, 500);
    });

    watch(
        () => route.path,
        () => {
          MenuComponent.hideDropdowns(undefined);

          if ((store.authentication.user || null) == null) {
            console.log("No user in authenticatedlayout - redirecting to login");
            // Go to login page instead of arc id
            router.push("/login");
          }

          nextTick(() => {
            reinitializeComponents();
          });
          removeModalBackdrop();
        }
    );

    return {
      toolbarDisplay,
      loaderEnabled,
      contentWidthFluid,
      loaderLogo,
      asideEnabled,
      subheaderDisplay,
      pageTitle,
      breadcrumbs,
      themeLightLogo,
      themeDarkLogo,
      routeName
    };
  },
});
